import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/common/seo";
import { WorksPage } from "../components/pageLayout/WorksPage";

export const works = graphql`
  query IllustrationPageQuery {
    works: allSanityWork(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        categories: { elemMatch: { title: { eq: "Illustration" } } }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              id
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const Illustration = ({ data }) => {
  const { works } = data;
  console.log(data);
  // console.log(works);
  return (
    <>
      <Seo title="Illustration" />

      <h1>Illustration</h1>
      <WorksPage works={works.edges} />
    </>
  );
};

export default Illustration;
